<template>
  <div id="poll-container">
    <template v-if="!error && question">
      <section class="section">
        <ValidationObserver
          class="container"
          ref="pollSubmitObserver"
          tag="div"
          v-slot="{ invalid }"
        >
          <form
            @keypress.enter.exact.prevent
            @submit.prevent="onSubmit"
            id="survey"
          >
            <div class="columns page-wrap is-centered is-vcentered">
              <component
                :is="`Q${question.type}`"
                :question="question"
                :value="changes"
                @input="onChange"
              ></component>
              <div class="is-flex is-justify-content-center">
                <b-button
                  :disabled="invalid"
                  :loading="loading"
                  native-type="submit"
                  type="is-primary"
                  class="submit-button"
                >
                  {{ $t('poll.vote') }}
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </section>
    </template>
    <ErrorMessage :error="error"></ErrorMessage>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { SET_POLL } from '@/store'
import { QuestionTypeComponentsMixin } from '@/mixins/question-type-components'
import { ErrorHandlerMixin } from '@/mixins/error-handler'
import { PollMixin } from '@/mixins/poll'
import { getPoll } from '@/api/response'

export default {
  name: 'PreviewPoll',
  mixins: [
    QuestionTypeComponentsMixin,
    ErrorHandlerMixin,
    PollMixin,
  ],
  components: {
    QSA: () => import("@/components/poll/SingleAnswer"),
    QMA: () => import("@/components/poll/MultipleAnswer")
  },
  methods: {
    ...mapMutations([SET_POLL]),
    async init() {
      this.error = null
      const loadingComponent = this.$buefy.loading.open()
      this.setLang()
      try {
        const { params: { code } } = this.$route
        const {
          data: { data },
        } = await getPoll(code)
        this[SET_POLL](data)
      } catch (error) {
        this.errorHandler(error)
      } finally {
        loadingComponent.close()
      }
    },
    async onSubmit() {
      return
    }
  },
  created() {
    this.init()
  }
}
</script>
<style lang="scss">
.title {
  color: $gray-10;
  display: block;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 22px;
  padding: 0.75rem 0;
}
.page-wrap {
  margin-bottom: 0 !important;
  min-height: unset !important;
}
</style>
