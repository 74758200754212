<template>
  <ValidationProvider
      tag="div"
      class="question supported-keyboard column"
      :rules="validationRules"
      v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="question-text__question" v-html="decodeHtml(question.question)"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content columns is-flex is-multiline">
        <b-field v-for="answer in question.answers" :key="answer.id" class="column is-half">
          <b-checkbox-button
            class="answer"
            @input="onInput(answer, $event)"
            v-model="selected"
            :native-value="answer.id"
            :disabled="loading || disabled.includes(answer.id)"
          >
            <span class="option" v-html="answer.answer"></span>
          </b-checkbox-button>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { PollQuestionMixin } from '@/mixins/poll-question'
import { UnFocusMixin } from '@/mixins/unfocus'

export default {
  name: 'MultipleAnswer',
  mixins: [PollQuestionMixin, UnFocusMixin],
  data() {
    return {
      selected: [],
      disabled: [],
    }
  },
  methods: {
    onInput(answer, selected){
      const isChecked = selected.includes(answer.id)
      const value = isChecked ? '1' : null

      const changes = { [[this.question.type, answer.id].join('_')]: value }
      this.$emit('input', changes)
      this.unFocus()
    }
  },
  created() {
    this.selected = []
  }
}
</script>
