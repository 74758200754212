<template>
  <span></span>
</template>

<script>
export default {
  name: 'SandboxHtml',
  props: {
    html: {
      type: String
    },
    result: {
      type: Object,
      default: () => ({})
    },
    script: {
      type: String,
      default: null
    }
  },
  methods: {
    onLoad () {
      document.documentElement.innerHTML = this.html
      document.body.style['paddingTop'] = '30px'
    },
    /* eslint-disable no-unused-vars */
    async preload (...args) {
      try {
        if (!this?.script) return;
        const functionString = this.script;

        return await (async(args) => {
          const self = args[0]
          const result = args[1]
          return eval("(async () => {" + functionString + "\n})()")
        })(args);

      } catch (error) {
        console.log(error);
      }
    }
    /* eslint-enable no-unused-vars */
  },
  mounted () {
    this.onLoad()
  },
  async beforeMount (){
    await new Promise(r => setTimeout(r, 250))
    await this.preload(this, this.result)
    await new Promise(r => setTimeout(r, 150))
  }
}
</script>

<style scoped>
.sandbox-html {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
}
</style>
