import { setLang } from '@/utils/lang'
import { setRtl } from '@/utils/survey'
import { isEqual } from 'lodash'

export const PollMixin = {
  data() {
    return {
      error: null,
      changes: {}
    }
  },
  computed: {
    targetOrigin() {
      return this.$route.query?.targetOrigin
    },
    code() {
      return this.$route.params?.code
    },
    loading() {
      return this.$store.getters.loading(this.code)
    },
    poll() {
      return this.$store.getters.poll(this.code)
    },
    response() {
      return this.$store.getters.response(this.code)
    },
    question() {
      return this.poll?.question
    },
    lang() {
      return this.$i18n.locale;
    },
    isFinished() {
      return !!this.response?.submitted_at
    },
    totalAnswer() {
      return this.$store.getters.pollResult(this.code)?.total || 0
    }
  },
  watch: {
    question (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        setTimeout(() => {
          this.sendMessage()
        }, 300);
			}
    },
    isFinished (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        setTimeout(() => {
          this.sendMessage()
        }, 300);
			}
    }
  },
  methods: {
    onChange(data) {
      this.changes = { ...this.changes, ...data }
    },
    sendMessage() {
      const height = document.getElementById('poll-container').offsetHeight
      window.parent.postMessage({ code: this.code, height }, this.targetOrigin)
    },
    setLang() {
      const { lang } = this.$route.query;
      this.$i18n.locale = lang;
      setLang(lang);
      if (['ar', 'fa', 'ur'].includes(lang)) setRtl(true);
    }
  },
}
