import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/surveys/:code",
    name: "Survey",
    component: () => import("@/views/Survey.vue"),
  },
  {
    path: "/surveys/:code/preview",
    name: "PreviewSurvey",
    component: () => import("@/views/PreviewSurvey.vue"),
  },
  {
    path: "/surveys/:code/pages/:pageCode/preview",
    name: "PreviewPage",
    component: () => import("@/views/PreviewPage.vue"),
  },
  {
    path: "/surveys/:code/questions/:questionCode/preview",
    name: "PreviewQuestion",
    component: () => import("@/views/PreviewQuestion.vue"),
  },
  {
    path: "/survey-templates/:code/preview",
    name: "PreviewSurveyTemplate",
    component: () => import("@/views/PreviewSurveyTemplate.vue"),
  },
  {
    path: "/survey-templates/:code/pages/:pageCode/preview",
    name: "PreviewTemplatePage",
    component: () => import("@/views/PreviewTemplatePage.vue"),
  },
  {
    path: "/survey-templates/:code/questions/:questionCode/preview",
    name: "PreviewTemplateQuestion",
    component: () => import("@/views/PreviewTemplateQuestion.vue"),
  },
  {
    path: "/surveys/:code/chat",
    name: "PreviewSurvey",
    component: () => import("@/views/ChatSurvey.vue"),
  },
  {
    path: "/polls/:code",
    name: "Poll",
    component: () => import("@/views/Poll.vue"),
  },
  {
    path: "/polls/:code/preview",
    name: "Poll",
    component: () => import("@/views/PreviewPoll.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
