<template>
  <div class="preview-survey" id="survey-scroller">
    <template v-if="!error && survey">
      <template v-if="!isFinished">
        <div class="is-hidden-desktop mobile-progress">
          <b-progress
            :max="max"
            :value="current"
            size="is-tiny"
            type="is-dark-green"
          >
          </b-progress>
        </div>
        <section class="section">
          <ValidationObserver
            class="container"
            ref="surveySubmitObserver"
            tag="div"
            v-slot="{ invalid }"
          >
            <form
              @keypress.enter.exact.prevent
              @submit.prevent="onSubmit"
              id="survey"
            >
              <transition-group
                :name="transitionType"
                tag="div"
                :class="{'is-hidden': invisible}"
                class="columns page-wrap is-centered is-vcentered"
                v-if="page"
              >
                <component
                  :key="question.qid"
                  v-for="question in questions"
                  :is="`Q${question.type}`"
                  :lang="lang"
                  :loading="loading"
                  :question="question"
                  :show-question-code="showQuestionCode"
                  :show-answer-code="showAnswerCode"
                  :value="changes"
                  :should-focus="questions.length === 1"
                  :sid="survey.sid"
                  :respondent="response"
                  :preload="preload"
                  :last-action="transitionType"
                  :result="changes"
                  :result-qcode="resultWithQCode"
                  @ready="(payload) => invisible = !payload"
                  @move-next="moveNext"
                  @submit="onSubmit"
                  @move-back="onBack"
                  @screen-out="onScreenOut"
                  @quota-full="onQuotaFull"
                  @mounted="syncWith"
                  @input="onChange"
                ></component>

                <MainNav
                  v-if="!isScreenOut && !isQuotaFull"
                  :key="page.pid"
                  :class="navWidth"
                  :backable="isBackable"
                  :current="current"
                  :hide-back-btn="!survey.is_backable"
                  :loading="loading"
                  :max="max"
                  :nextable="!(current === max) || invalid"
                  :next-text="$t(`btn.${isLastPage ? 'submit' : 'next'}`)"
                  @back="onBack"
                ></MainNav>
              </transition-group>
              <FormNav
                v-if="!isScreenOut && !isQuotaFull"
                :back-text="$t('btn.previous')"
                :backable="isBackable"
                :current="current"
                :hide-back-btn="!survey.is_backable"
                :loading="loading"
                :max="max"
                :next-text="$t(`btn.${isLastPage ? 'submit' : 'next'}`)"
                :nextable="!(current === max) || invalid"
                @back="onBack"
              ></FormNav>
            </form>
          </ValidationObserver>
        </section>
      </template>
      <section class="hero is-fullheight" v-if="isFinished">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered is-vcentered">
              <div class="column is-8">
                <div class="has-text-centered" v-if="!endType">
                  <h1 class="title">
                    {{ $t("endMessage") }}
                  </h1>
                </div>
                <SandboxHtml
                  :html="translations.end_msg"
                  :result="resultWithQCode"
                  :script="endPageScript"
                  v-if="endType === 'message'"
                ></SandboxHtml>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <ErrorMessage :error="error" @ready="(payload) => invisible = !payload"></ErrorMessage>
  </div>
</template>

<script>
import _ from 'lodash'

import FormNav from "@/components/FormNav";
import MainNav from "@/components/MainNav";

import { getSurveyFull } from "@/api/response";
import { QuestionTypeComponentsMixin } from "@/mixins/question-type-components";
import { SurveyMetaMixin } from "@/mixins/survey-meta";
import { checkConditions } from "@/utils/question";
import { handleCarryForwardOption } from '@/utils/question'
import { LocaleSwitchingMixin } from "@/mixins/locale-switching";
import { SurveyEndMixin } from "@/mixins/survey-end";
import { SurveyLayoutMixin } from "@/mixins/survey-layout";
import { ScrollToTopMixin } from "@/mixins/scroll-to-top";
import { ErrorHandlerMixin } from "@/mixins/error-handler";
import { PreviewMixin } from "@/mixins/preview";
import { EnterSubmitMixin } from "@/mixins/keyboard-binding";
import { CustomizedScriptMixin } from "@/mixins/customized-script";
import { SET_SURVEY } from "../store";
import { questionKey } from '@/utils/question';

export default {
  name: "PreviewSurvey",
  mixins: [
    EnterSubmitMixin,
    QuestionTypeComponentsMixin,
    SurveyMetaMixin,
    LocaleSwitchingMixin,
    SurveyEndMixin,
    ScrollToTopMixin,
    ErrorHandlerMixin,
    PreviewMixin,
    SurveyLayoutMixin,
    CustomizedScriptMixin
  ],
  components: {
    FormNav,
    MainNav,
  },
  data() {
    return {
      survey: null,
      loading: false,
      isFinished: false,
      isScreenOut: false,
      isQuotaFull: false,
      current: 0,
      transitionType: "next-page",
      invisible: true,
      response: {
        _id: 'preview'
      },
    };
  },
  computed: {
    resultWithQCode(){
      const result = Object.keys(this.changes).reduce((previous, key) => {
        let [qid, sqcode, acode] = key.split('_')
        const code = this.survey.questions.find(question => question.qid == qid)?.code || key
        return {...previous, [questionKey(code, sqcode, acode)]: this.changes[key]}
      }, {});
      return result;
    },
    page() {
      return this.survey?.pages[this.current];
    },
    questions() {
      const self = this

      const questions = self.survey?.questions
          .filter((question) => question.pid === self.page?.pid)
          .filter((question) => handleCarryForwardOption(question, self.changes))
          .filter((question) => {
              return checkConditions(question?.conditions, self.changes)
          })

        _.forEach(questions, function(value) {
            if (value?.sub_questions.length > 0) {
              value.sub_questions = value.sub_questions.filter((subQuestion) => {
                return checkConditions(subQuestion.conditions, self.changes)
              })
            }

          if (value?.answers.length > 0) {
            value.answers = value.answers.filter((answerQuestion) => {
              return checkConditions(answerQuestion.conditions, self.changes)
            })
          }
        });

      return questions
    },
    isBackable() {
      return this.current > 0 && this.survey?.questions?.slice(0, this.current).findIndex((question) => !question?.options?.hidden) !== -1;
    },
    isScrollable() {
      return this.survey?.is_scrollable;
    },
    isAutoMoveNext() {
      return this.survey?.is_auto_move_next === undefined ? true : this.survey?.is_auto_move_next;
    },
  },
  watch: {
    "$route.query.page_code"(pageCode) {
      this.selectPageByCode(pageCode);
    },
    "$route.query.page_id"(pageId) {
      this.selectPageById(pageId);
    },
    "$route.query.page_index"(pageIndex) {
      this.current = +pageIndex;
    }
  },
  methods: {
    async init() {
      this.error = null;
      const loadingComponent = this.$buefy.loading.open();
      try {
        const {
          params: { code },
        } = this.$route;
        const {
          data: { data },
        } = await getSurveyFull(code);
				this.current = data?.questions.findIndex((question) => !question?.options?.hidden);
        this.survey = data;
        this[SET_SURVEY](data);

        const lang = this.$route.query?.lang;
        if (lang && !data?.supported_languages.includes(lang)) {
          this.error = {
            code: 400,
            message: this.$t("errors.LANGUAGE_NOT_SUPPORTED"),
          };
        }

        const { page_code, page_id, page_index } = this.$route.query;
        if (page_code) this.selectPageByCode(page_code);
        else if (page_id) this.selectPageById(page_id);
        else if (page_index) this.current = +page_index;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        loadingComponent.close();
      }
    },
    async onQuotaFull(){
      if (this.endType === "completeurl" && this.translations.quota_full_url) this.redirect('quota_full_url');
    },
    async onScreenOut(){
      if (this.endType === "completeurl" && this.translations.screen_out_url) this.redirect('screen_out_url');
    },

    async moveNext(force = false) {
      if ((this.questions.length >= 2 || this.isLastPage || !this.isAutoMoveNext) && !force) return;
      await this.onSubmit();
    },

    async onSubmit() {
      const isValid = await this.$refs.surveySubmitObserver?.validate();
      if (!isValid) {
        const el = screen.width > 1023 ? document.querySelector(".help.is-danger,.has-text-danger,.has-error") : document.querySelector(".help.is-danger,.label.has-text-danger,.has-error");
				if (el) {
					window.setTimeout(function () {
						el.scrollIntoView({ behavior: "smooth", block: "center"});
					}, 200)
				}
				return;
			}

      this.transitionType = "next-page";
      this.loading = true;
			if (this.current === this.survey?.questions?.findLastIndex((question) => !question?.options?.hidden)) {
				this.current = this.max;
			} else {
				this.current += this.survey?.questions?.slice(this.current+1).findIndex((question) => !question?.options?.hidden) + 1;
			}

      if (this.questionLang && this.lang !== this.questionLang) {
        await this.$router.replace({
          ...this.$route.query,
          lang: this.questionLang,
        });
      }

      if (this.current >= this.max) this.isFinished = true;

      // Handle empty page
      if (this.page && !this.questions?.length) await this.onSubmit();

      this.loading = false;
      this.scrollToTop();
    },
    selectPageByCode(pageCode) {
      const pageIndex = this.survey?.pages?.findIndex(
        (page) => page.code === pageCode
      );
      if (pageIndex > -1) this.current = pageIndex;
    },
    selectPageById(pageId) {
      const pageIndex = this.survey?.pages?.findIndex(
        (page) => page.pid === pageId
      );
      if (pageIndex > -1) this.current = pageIndex;
    },
    async onBack() {
      this.transitionType = "back-page";
      this.loading = true;
      if (this.page) this.current -= this.survey?.questions?.slice(0, this.current).findIndex((question) => !question?.options?.hidden);
      this.loading = false;
      if (!this.questions?.length) await this.onBack();
    },
    redirectUrl(urlType) {
      const regex = /\[\w+]/g;
      let url = this.translations?.[urlType];
      let m;
      while ((m = regex.exec(url)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match) => {
          const key = match.replace("[", "").replace("]", "");
          url = url.replace(`${match}`, this.hiddenData[key] ?? `${match}`);
        });
      }
      return url;
    },
    async redirect(urlType) {
      const url = this.redirectUrl(urlType)
      const isExternalURL =
        new URL(url).origin !== location.origin;

      const vueRoute = this.$router.resolve(new URL(url).pathname);
      const routeName = vueRoute.route.name;
      if (isExternalURL || routeName !== "Survey")
        window.location.href = url;
      try {
        const {
          data: { data },
        } = await getSurveyFull(vueRoute.route.params.code);
        if (data?.is_active) window.location.href = url;
      } catch (err) {
        console.log(err);
      }
      this.showReward = true;
      return;
    },
  },
  async created() {
    await this.init();
  },
};
</script>
