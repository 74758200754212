<template>
  <div class="survey" id="survey-scroller">
    <b-loading :active.sync="invisible" :can-cancel="false"></b-loading>
    <Policy
      :lang="lang"
      @confirmed="showPolicy = false"
      @ready="(payload) => invisible = !payload"
      v-if="showPolicy"
    ></Policy>
    <Reward
      :lang="lang"
      :rid="response._id"
      :sid="survey.sid"
      @ready="(payload) => invisible = !payload"
      :uuid="response.uuid"
      :result="resultWithQCode"
      :script="endPageScript"
      v-else-if="!forceHideReward && isFinished && (endType === 'defaultrewards' || showReward)"
    ></Reward>
    <Summary
      :lang="lang"
      :title="translations.title"
      :survey="survey"
      :response="response"
      :show-question-code="showQuestionCode"
      :show-answer-code="showAnswerCode"
      @submit="onSubmitReview"
      v-else-if="showSummary"
    ></Summary>
    <template v-else-if="!error && survey">
      <template v-if="!isFinished">
        <div class="is-hidden-desktop mobile-progress">
          <b-progress
            :max="max"
            :value="current"
            size="is-tiny"
            type="is-dark-green"
          >
          </b-progress>
        </div>
        <section class="section">
          <ValidationObserver
            class="container"
            ref="surveySubmitObserver"
            tag="div"
            v-slot="{ invalid }"
          >
            <form
              @keypress.enter.exact.prevent
              @submit.prevent="onSubmit"
              id="survey"
            >
              <transition-group
                :name="transitionType"
                tag="div"
                :class="{'opacity-0': invisible}"
                class="columns page-wrap is-centered is-vcentered"
                v-if="page"
              >
                <component
                  :ref="`ref${question.code}`"
                  :key="question.qid"
                  :name="transitionType"
                  v-for="question in questions"
                  :is="`Q${question.type}`"
                  :lang="lang"
                  :loading="loading"
                  :question="question"
                  :show-question-code="showQuestionCode"
                  :show-answer-code="showAnswerCode"
                  :value="changes"
                  :should-focus="questions.length === 1"
                  :sid="survey.sid"
                  :respondent="response"
                  :last-action="transitionType"
                  :result="result"
                  :result-qcode="resultWithQCode"
                  @ready="(payload) => invisible = !payload"
                  @move-next="moveNext"
                  @submit="onSubmit"
                  @move-back="onBack"
                  @screen-out="onScreenOut"
                  @quota-full="onQuotaFull"
                  @mounted="syncWith"
                  @input="onChange"
                  @is-enter="isEnterActionSelect"
                ></component>

                <MainNav
                  v-if="!isScreenOut && !isQuotaFull"
                  :key="page.pid"
                  :class="navWidth"
                  :backable="isBackable"
                  :current="current"
                  :hide-back-btn="!survey.is_backable"
                  :loading="loading"
                  :max="max"
                  :nextable="!(current === max) || invalid"
                  :next-text="nextText"
                  @back="onBack"
                ></MainNav>
              </transition-group>
              <FormNav
                v-if="!isScreenOut && !isQuotaFull"
                :back-text="$t('btn.previous')"
                :backable="isBackable"
                :current="current"
                :hide-back-btn="!survey.is_backable"
                :loading="loading"
                :max="max"
                :next-text="nextText"
                :nextable="!(current === max) || invalid"
                :survey-level="survey.survey_chain_level"
                @back="onBack"
              ></FormNav>
            </form>
          </ValidationObserver>
        </section>
      </template>
      <section class="hero is-fullheight" v-if="isFinished">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered is-vcentered">
              <div class="column is-8">
                <div class="has-text-centered" v-if="!endType">
                  <h1 class="title">
                    {{ $t("endMessage") }}
                  </h1>
                </div>
                <SandboxHtml
                  :html="translations.end_msg"
                  :result="resultWithQCode"
                  :script="endPageScript"
                  v-if="endType === 'message'"
                ></SandboxHtml>
                <SandboxHtml
                  :html="$t('errors.URL_NOT_FOUND')"
                  v-if="urlError"
                ></SandboxHtml>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <ErrorMessage :error="error" @ready="(payload) => invisible = !payload"></ErrorMessage>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { BACK, NEXT, START_SURVEY, UPDATE_CHANGES, SCREEN_OUT, LOGIN, QUOTA_FULL, SUBMIT_PREVIEW, PREVIEW_SURVEY } from "../store";
import { getSurveyFull } from "@/api/response";
import FormNav from "@/components/FormNav";
import MainNav from "@/components/MainNav";
import { QuestionTypeComponentsMixin } from "@/mixins/question-type-components";
import { SurveyMetaMixin } from "@/mixins/survey-meta";
import { LocaleSwitchingMixin } from "@/mixins/locale-switching";
import { SurveyEndMixin } from "@/mixins/survey-end";
import { ScrollToTopMixin } from "@/mixins/scroll-to-top";
import { ErrorHandlerMixin } from "@/mixins/error-handler";
import { EnterSubmitMixin } from "@/mixins/keyboard-binding";
import { SurveyLayoutMixin } from "@/mixins/survey-layout";
import { setLang } from '@/utils/lang';
import { setRtl } from '@/utils/survey';
import _pickBy from 'lodash.pickby';
export default {
  name: "Survey",
  mixins: [
    EnterSubmitMixin,
    QuestionTypeComponentsMixin,
    SurveyMetaMixin,
    LocaleSwitchingMixin,
    SurveyEndMixin,
    ScrollToTopMixin,
    ErrorHandlerMixin,
    SurveyLayoutMixin
  ],
  components: {
    FormNav,
    MainNav,
  },
  data() {
    return {
      error: null,
      changes: {},
      showPolicy: false,
      showReward: false,
      showSummary: false,
      forceHideReward: false,
      transitionType: "",
      invisible: true,
      urlError: false
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading(this.code);
    },
    survey() {
      return this.$store.getters.survey(this.code);
    },
    page() {
      return this.$store.getters.page(this.code);
    },
    questions() {
      return this.$store.getters.questions(this.code);
    },
    response() {
      return this.$store.getters.response(this.code);
    },
    result() {
      return this.$store.getters.result(this.code);
    },
    resultWithQCode() {
      return this.$store.getters.resultWithQCode(this.code);
    },
    hiddenData() {
      return this.$store.getters.hiddenData(this.code);
    },
    currentState() {
      return this.$store.getters.currentState(this.code);
    },
    lang() {
      return this.$i18n.locale;
    },
    current() {
      return this.currentState?.pidx;
    },
    // mergedChanges() {
    //   return { ...this.result, ...this.changes };
    // },
    isFinished() {
      return !!this.response?.submitted_at;
    },
    isScreenOut(){
      return this.response?.is_screen_out;
    },
    isQuotaFull(){
      return this.response?.is_quota_full;
    },
    isBackable() {
      return this.currentState?.is_backable;
    },
    isScrollable() {
      return this.survey?.is_scrollable;
    },
    isAutoMoveNext() {
      return this.survey?.is_auto_move_next === undefined ? true : this.survey?.is_auto_move_next;
    },
    nextText() {
      if (this.isLastPage && this.showResponseSummary) {
        return this.$t('btn.review_and_submit');
      }
      if (this.isLastPage && this.translations.submit_text) {
        return this.translations.submit_text;
      }
      return this.$t(`btn.${this.isLastPage ? "submit" : "next"}`);
    },
  },
  watch: {
    isFinished() {
      if (this.endType === "completeurl") this.redirect('completed_url');
    },
  },
  methods: {
    ...mapActions([START_SURVEY, BACK, NEXT, SCREEN_OUT, LOGIN, QUOTA_FULL, PREVIEW_SURVEY, SUBMIT_PREVIEW]),
    ...mapMutations([UPDATE_CHANGES]),
    async init() {
      const loadingComponent = this.$buefy.loading.open();
      const { lang } = this.$route.query;
      this.$i18n.locale = lang;
      setLang(lang);
      if (['ar', 'fa', 'ur'].includes(lang)) setRtl(true);
      this.error = null;
      try {
        const {
          params: { code },
          query,
        } = this.$route;
        await this[START_SURVEY]({ code, ...query });
        this.showPolicy = this.survey.show_policy;
      } catch (err) {
        this.errorHandler(err);
      } finally {
        loadingComponent.close();
      }
    },

    async moveNext(force = false) {
      if ((this.questions.length >= 2 || this.isLastPage || !this.isAutoMoveNext) && !force) return;
      await this.onSubmit();
    },

    async isEnterActionSelect() {
      const kbEvent = new KeyboardEvent('keypress', { cancelable: true, key: 'Enter', which: 13,  });
      window.dispatchEvent(kbEvent);
    },

    async onSubmitReview() {
      this.error = null
      this.transitionType = "next-page"
      try {
        await this[SUBMIT_PREVIEW]({
          code: this.code,
          data: { response_id: this.response._id },
        })

        this.showSummary = false
        this.scrollToTop()
      } catch (err) {
        this.errorHandler(err);
      }
    },

    async onSubmit() {
      const isValid = await this.$refs.surveySubmitObserver.validate();
      if (!isValid || this.loading) {
        const el = screen.width > 1023 ? document.querySelector(".help.is-danger,.has-text-danger,.has-error") : document.querySelector(".help.is-danger,.label.has-text-danger,.has-error");
				if (el) {
					window.setTimeout(function () {
						el.scrollIntoView({ behavior: "smooth", block: "center"});
					}, 200)
				}
				return;
			}

      this.error = null;
      this.transitionType = "next-page";
      try {
        if (this.questions.length && this.questions.find(i => i.type === 'C')) {
          await this[LOGIN]({
            code: this.code,
            data: { response_id: this.response._id, username: this.changes.username },
          });
        } else {
          const changes = _pickBy(this.changes, (index, key)=> {
            return this.questions.map(question => question.qid).includes(Number.parseInt(key.split('_')?.[0]))
          })
          await this[NEXT]({
            code: this.code,
            data: { response_id: this.response._id, result: changes },
          });
        }

        let changes = this.currentState?.changes || {};
        for (const [key, value] of Object.entries(changes)) {
          const exist = this.questions.find((question) => question.id === key.split('_')?.[0].toLowerCase())
          changes[key] = exist ? value : null;
        }

        this.changes = changes
        this.scrollToTop();

        if (this.page && !this.questions.length) await this.onSubmit();

        if (!this.page && this.showResponseSummary) {
          this.showSummary = true
          await this[PREVIEW_SURVEY]({
            code: this.code,
            data: { response_id: this.response._id },
          })
        }
      } catch (err) {
        this.errorHandler(err);
      }
    },
    async onBack() {
      this.error = null;
      this.$refs.surveySubmitObserver.reset();
      this.transitionType = "back-page";
      try {
        await this[BACK]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes },
        });
        this.changes = this.currentState?.changes || {};
        if (this.page && !this.questions.length) await this.onBack();
      } catch (err) {
        this.errorHandler(err);
      }
    },
    async onScreenOut(){
      try {
        await this[SCREEN_OUT]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes },
        });
        if (this.endType === "completeurl" && this.translations.screen_out_url) this.redirect('screen_out_url');
      } catch (err) {
        this.errorHandler(err);
      }
    },
    async onQuotaFull(){
      try {
        await this[QUOTA_FULL]({
          code: this.code,
          data: { response_id: this.response._id, result: this.changes },
        });
        if (this.endType === "completeurl" && this.translations.quota_full_url) this.redirect('quota_full_url');
      } catch (err) {
        this.errorHandler(err);
      }
    },
    onChange(data) {
      this.changes = { ...this.changes, ...data };
      // this.changes = {};
      this[UPDATE_CHANGES]({ code: this.code, changes: this.changes });
    },
    redirectUrl(urlType) {
      const regex = /\[\w+]/g;
      let url = this.translations?.[urlType];
      let m;
      while ((m = regex.exec(url)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match) => {
          const key = match.replace("[", "").replace("]", "");
          url = url.replace(`${match}`, this.hiddenData[key] ?? `${match}`);
        });
      }
      return url;
    },
    isValidUrl(url) {
        try { 
          return Boolean(new URL(url)); 
        }
        catch(e){ 
          return false; 
        }
    },
    async redirect(urlType) {
      let url = this.redirectUrl(urlType)
      if (url) {
        const isValidUrl = this.isValidUrl(url)
        if (!isValidUrl) {
          this.urlError = true;
          return;
        }
      }
      const isExternalURL =
        new URL(url).origin !== location.origin;

      const vueRoute = this.$router.resolve(new URL(url).pathname);
      const routeName = vueRoute.route.name;
      if (isExternalURL || routeName !== "Survey")
        window.location.href = url;
      try {
        const {
          data: { data },
        } = await getSurveyFull(vueRoute.route.params.code);
        if (data?.is_active) window.location.href = url;
      } catch (err) {
        console.log(err);
      }
      this.showReward = false;
      return;
    },
    forceReloadIfNotRestorable () {
      window.onpageshow = (event) => {
        if (event.persisted) {
          window.location.reload();
        }
      };
    }
  },
  async created() {
    this.forceReloadIfNotRestorable()
    await this.init();
  },
};
</script>
