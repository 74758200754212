<template>
  <div id="poll-container">
    <template v-if="!error && question">
      <section class="section">
        <template v-if="isFinished">
          <div class="card px-4 py-2">
            <div class="card-content py-0">
              <div class="poll-title-container">
                <div class="poll-title">
                  <p class="title">{{ $t('poll.poll_result') }}</p>
                </div>
                <div class="total-answer">
                  <b-icon icon="account-heart" class="mr-1"></b-icon>
                  <span class="has-text-primary">{{ totalAnswer }}</span>
                </div>
              </div>
            </div>
            <hr class="my-0" />
            <component
              :is="`R${question.type}`"
              :question="question"
              :code="code"
            ></component>
          </div>
        </template>
        <template v-else>
          <ValidationObserver
            class="container"
            ref="pollSubmitObserver"
            tag="div"
            v-slot="{ invalid }"
          >
            <form
              @keypress.enter.exact.prevent
              @submit.prevent="onSubmit"
              id="survey"
            >
              <div class="columns page-wrap is-centered is-vcentered">
                <component
                  :is="`Q${question.type}`"
                  :question="question"
                  :value="changes"
                  @input="onChange"
                ></component>
                <div class="is-flex is-justify-content-center">
                  <b-button
                    :disabled="invalid"
                    :loading="loading"
                    native-type="submit"
                    type="is-primary"
                    class="submit-button"
                  >
                    {{ $t('poll.vote') }}
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </section>
    </template>
    <ErrorMessage :error="error"></ErrorMessage>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { START_POLL, SUBMIT_POLL } from '@/store'
import { QuestionTypeComponentsMixin } from '@/mixins/question-type-components'
import { ErrorHandlerMixin } from '@/mixins/error-handler'
import { LocaleSwitchingMixin } from '@/mixins/locale-switching'
import { PollMixin } from '@/mixins/poll'

export default {
  name: 'Poll',
  mixins: [
    QuestionTypeComponentsMixin,
    ErrorHandlerMixin,
    LocaleSwitchingMixin,
    PollMixin,
  ],
  components: {
    QSA: () => import("@/components/poll/SingleAnswer"),
    QMA: () => import("@/components/poll/MultipleAnswer"),
    RSA: () => import("@/components/poll/result/SingleAnswer"),
    RMA: () => import("@/components/poll/result/MultipleAnswer"),
  },
  methods: {
    ...mapActions([START_POLL, SUBMIT_POLL]),
    async init() {
      this.error = null
      const loadingComponent = this.$buefy.loading.open()
      this.setLang()
      try {
        const { params: { code }, query } = this.$route
        if (code) {
          await this[START_POLL]({ code, ...query })
        }
      } catch (error) {
        this.errorHandler(error)
      } finally {
        loadingComponent.close()
      }
    },
    async onSubmit() {
      await this[SUBMIT_POLL]({
        code: this.code,
        data: { response_id: this.response._id, result: this.changes },
      })
    }
  },
  created() {
    this.init()
  }
}
</script>
<style lang="scss">
.poll-title-container {
  display: grid;
}
.poll-title-container > * {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: center;
}
.poll-title-container > *:last-child {
  justify-self: right;
}
.total-answer {
  font-size: 1.25rem;
  align-self: center;
  display: inline-block;
}
.title {
  color: $gray-10;
  display: block;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.75rem 0;
  margin: 0 !important;
  display: inline-block;
}
.page-wrap {
  margin-bottom: 0 !important;
  min-height: unset !important;
}
.is-half {
  flex: none;
  width: 50%;
}
@media only screen and (max-width: 400px) {
  .is-half {
    flex: none;
    width: 100%;
  }
}
</style>
