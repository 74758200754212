<template>
  <ValidationProvider
    :rules="validationRules"
    class="question supported-keyboard column"
    tag="div"
    v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="question-text__question" v-html="decodeHtml(question.question)"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content columns is-flex is-multiline">
        <b-field :key="answer.id" v-for="answer in question.answers" class="column is-half mb-4">
          <b-radio-button
            class="answer"
            type="is-primary"
            :disabled="loading"
            :native-value="answer.id"
            :value="value[`${question.type}`]"
            @input="onChange"
          >
            <span class="option" v-html="answer.answer"></span>
          </b-radio-button>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { PollQuestionMixin } from '@/mixins/poll-question'
import { UnFocusMixin } from '@/mixins/unfocus'

export default {
  name: 'SingleAnswer',
  mixins: [PollQuestionMixin, UnFocusMixin],
  methods: {
    onChange(value) {
      this.$emit('input', { [this.question.type]: value })
    },
  },
}
</script>
