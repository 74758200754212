import debounce from 'lodash/debounce';

export const PollQuestionMixin = {
  props: {
    loading: {
      type: Boolean,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Number, Object, Date],
    },
    shouldFocus: {
      type: Boolean,
      default: true
    },
    code: {
      type: String,
    },
    respondent: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    result() {
      return this.$store.getters.result(this.code)
    },
    pollResult() {
      return this.$store.getters.pollResult(this.code)
    },
    answers() {
      return this.question?.answers
    },
    validationRules() {
      const rules = {};
      rules.mandatory = { type: this.question.type };
      return rules;
    },
  },
  methods: {
    decodeHtml(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },
    autoFocus: debounce(function() {
      if (this.isIos) return;
      const el = document.querySelectorAll(
        'INPUT[type=text],INPUT[type=number],TEXTAREA'
      )[0];
      if (el && this.shouldFocus) {
        el.focus();
        el.click();
      }
    }, 250),
    clearData(){
      if (typeof this.ownClearData === 'function'){
        this.ownClearData()
      }
      this.selected = []
      this.$emit('input', Object.keys(this.value).reduce((acc, curr) => ({...acc, [curr]: ''}), {}))
    },
    getAnswerResult(id, type = 'value') {
      return this.pollResult?.result?.find(item => item.id == id)?.[type] || 0
    }
  },
  mounted() {
    this.$root.$on('validation-error', this.clearData)
    this.autoFocus();
  }
};
