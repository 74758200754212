<template>
  <div class="card-content">
    <div class="question-text">
      <p class="question-text__question" v-html="decodeHtml(question.question)"></p>
    </div>
    <div
      v-for="answer in answers"
      :key="answer.id"
      class="has-text-weight-bold mb-3"
      :class="{'is-selected': isSelected(answer.id)}"
    >
      <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
        <span class="option" v-html="answer.answer"></span>
        <span class="percent">
          <b-icon icon="check" type="is-primary" class="d-none"></b-icon>
          {{ getAnswerResult(answer.id, 'percent') }}%
        </span>
      </div>
      <b-progress
        :value="getAnswerResult(answer.id, 'percent')"
        :type="isSelected(answer.id) ? 'is-light-green' : 'is-lighter-green'"
        class="mb-2"
      />
      <div class="is-flex is-justify-content-end has-text-gray">
        {{ getAnswerResult(answer.id, 'value') }} {{ $t('poll.votes') }}
      </div>
    </div>
  </div>
</template>
<script>
import { PollQuestionMixin } from '@/mixins/poll-question'
import { UnFocusMixin } from '@/mixins/unfocus'

export default {
  name: 'MultipleAnswer',
  mixins: [PollQuestionMixin, UnFocusMixin],
  data() {
    return {
      selected: [],
      disabled: [],
    }
  },
  methods: {
    isSelected(value) {
      return this.result[`${this.question.type}_${value}`]
    }
  },
  created() {
    this.selected = []
  }
}
</script>
<style lang="scss" scoped>
.question-text {
  margin-bottom: 2rem;
  .question-text__question {
    font-size: 1.5rem;
    color: $gray-10;
    line-height: 2rem;
      ul li {
        list-style-type: circle;
      }
  }
}
.has-text-gray {
  color: $gray-8;
}
.d-none {
  display: none;
}
.is-selected {
  .percent {
    color: $primary;
    font-weight: bolder;
  }
  .icon {
    display: inline-flex !important;
  }
}
</style>
