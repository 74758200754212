export const SurveyEndMixin = {
  computed: {
    endType () {
      return this.survey?.end_type
    },
    endPageScript () {
      return this.survey?.end_page_script?.[this.endType?.includes('reward') ? 'reward' : this.endType]
    },
  },
  watch: {
    isFinished (value) {
			sessionStorage.clear();
      if (value && this.endType === 'completeurl') this.redirect()
    },
  }
}
